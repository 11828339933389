import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Typography, Box, Grid, Paper } from "@mui/material";

function App() {
  const baseURL = "http://kbc.pllatinum.tech/question";

  const [student, setStudent] = useState({ name: "", uniqueId: "", score: 0 });
  const [questions, setQuestions] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [leaderboard, setLeaderboard] = useState(null);

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${baseURL}/register`, student);
      setResponseMessage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetScores = async () => {
    try {
      const response = await axios.put(`${baseURL}/reset-scores`);
      setResponseMessage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateQuestions = async () => {
    try {
      const response = await axios.put(`${baseURL}/update-questions`, questions);
      setResponseMessage("Questions updated successfully.");
    } catch (error) {
      console.error(error);
    }
  };

  const handleTruncateQuestions = async () => {
    try {
      const response = await axios.delete(`${baseURL}/truncate-questions`);
      setResponseMessage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetPlayedStatus = async () => {
    try {
      const response = await axios.put(`${baseURL}/reset-played`);
      setResponseMessage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadLeaderboardByPlayed = async () => {
    try {
      const response = await axios.get(`http://kbc.pllatinum.tech/leaderboard/download`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "leaderboard_played.xlsx");
      document.body.appendChild(link);
      link.click();
      setResponseMessage("Leaderboard by Played downloaded successfully.");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadLeaderboard = async () => {
    try {
      const response = await axios.get(`http://kbc.pllatinum.tech/leaderboard/download1`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "leaderboard.xlsx");
      document.body.appendChild(link);
      link.click();
      setResponseMessage("Leaderboard downloaded successfully.");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Question Management System
      </Typography>

      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5">Register Student</Typography>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={student.name}
          onChange={(e) => setStudent({ ...student, name: e.target.value })}
        />
        <TextField
          label="Unique ID"
          fullWidth
          margin="normal"
          value={student.uniqueId}
          onChange={(e) => setStudent({ ...student, uniqueId: e.target.value })}
        />
        <Button variant="contained" onClick={handleRegister}>
          Register
        </Button>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5">Update Questions</Typography>
        <TextField
          label="Questions JSON"
          multiline
          fullWidth
          rows={4}
          margin="normal"
          value={JSON.stringify(questions)}
          onChange={(e) => setQuestions(JSON.parse(e.target.value))}
        />
        <Button variant="contained" onClick={handleUpdateQuestions}>
          Update Questions
        </Button>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={handleTruncateQuestions}
          >
            Truncate Questions
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleResetScores}
          >
            Reset Scores
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleResetPlayedStatus}
          >
            Reset Played Status
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleDownloadLeaderboardByPlayed}
          >
            Download Leaderboard (Played)
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleDownloadLeaderboard}
          >
            Download Leaderboard
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
        {responseMessage}
      </Typography>
    </Box>
  );
}

export default App;
